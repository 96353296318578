import React from "react";
import "./Institutional.scss";

const InstitutionalEn = () => {
  return (
    <section id="Institutional" className="Institutional">
      <div className="Institutional-container">
        <div className="Institutional-container_card1">
          <p className="Institutional-container_card1--txt">
            We have over 13 years of experience as a GSA in Argentina.
          </p>
          <div className="Institutional-container_card2">
            <div className="Institutional-container_card2--contentTxt">
              <p className="Institutional-container_card2--contentTxt__txt">
                Our company was founded in June 2002 to take on the
                representation of KLM Royal Dutch Airlines in the passenger area
                with a daily operation via SAO.
              </p>
              <p className="Institutional-container_card2--contentTxt__txt">
                Our mission is to be a top-tier outsourcing service provider for
                airlines.
              </p>
              <p className="Institutional-container_card2--contentTxt__txt">
                Our vision is to deliver Quality and Reliability to our clients,
                along with offering an integrated network of Sales, Marketing,
                Reservations, and Cargo Handling services, ensuring the
                represented company maintains its Brand Identity, while working
                as PARTNERS in the development of the local market.
              </p>
              <p className="Institutional-container_card2--contentTxt__txt">
                Our team is composed of qualified and experienced personnel in
                this industry.
              </p>
              <p className="Institutional-container_card2--contentTxt__txt">
                We specialize in providing Cargo services, as well as
                comprehensive handling of the complete 'On/Off-Line' operation.
              </p>
            </div>
            <div className="Institutional-container_card2--contentImg">
              <img
                className="Institutional-container_card2--contentImg__imgMobile"
                src="./Institutional/InstitutionalMobile.jpg"
              ></img>
              <img
                className="Institutional-container_card2--contentImg__imgDesktop"
                src="./Institutional/InstitutionalDesktop.jpg"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstitutionalEn;
