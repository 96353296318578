import React, { useState } from "react";
import "./NavBar.scss";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = section.getBoundingClientRect().top;
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      const targetScrollPosition = scrollPosition + offset - 80;

      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    }
    closeMenu();
  };

  return (
    <section id="top" className={`top-nav ${isMenuOpen ? "menu-open" : ""}`}>
      <input
        id="menu-toggle"
        type="checkbox"
        checked={isMenuOpen}
        onChange={toggleMenu}
      />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul className="menu">
        <li onClick={() => scrollToSection("Home")}>Inicio</li>
        <li onClick={() => scrollToSection("Institutional")}>Institucional</li>
        <li onClick={() => scrollToSection("Representaions")}>
          Representaciones
        </li>
        <li onClick={() => scrollToSection("Services")}>Servicios</li>
        <li onClick={() => scrollToSection("Forms")}>Formularios</li>
        <li onClick={() => scrollToSection("LinksOfInterest")}>
          Links de interés
        </li>
        <li onClick={() => scrollToSection("Contact")}>Contacto</li>
      </ul>
      <div className="containerimg">
        <img className="containerimg-img" src="./NavBar/blanco.png"></img>
      </div>
      <Link className="traduc" to={"/En "}>
        <img className="traduc-img" src="./NavBar/usa.png"></img>
        <div className="traduc-txt">English</div>
      </Link>
    </section>
  );
};

export default NavBar;
