import React from "react";
import "./Representations.scss";

const RepresentationsEn = () => {
  return (
    <section id="Representations" className="Representations">
      <div className="Representations-container">
        <div className="Representations-container_title">
          Handling cargo service provision
        </div>
        <div className="Representations-container_content"></div>
        <div className="Representations-container_content--delta">
          <img
            className="Representations-container_content--delta__logo"
            src="Representations/logoDelta.jpg"
          ></img>
          <div className="Representations-container_content--delta__alcd">
            In cooperation with:{" "}
            <img
              className="Representations-container_content--delta__alcd---img"
              src="Representations/alcd.jpg"
            ></img>
          </div>
        </div>
        <div className="Representations-container_content--qatar">
          <img
            className="Representations-container_content--qatar__logo"
            src="Representations/logoQatar.svg"
          ></img>
          <div className="Representations-container_content--qatar__globe">
            In cooperation with:{" "}
            <img
              className="Representations-container_content--qatar__globe---img"
              src="Representations/GlobeAirCargo.png"
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RepresentationsEn;
