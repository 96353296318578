import React from "react";
import "./LinksOfInterest.scss";

const LinksOfInterest = () => {
  return (
    <section id="LinksOfInterest" className="LinksOfInterest">
      <div className="LinksOfInterest-container">
        <div className="LinksOfInterest-container_title">Links of Interest</div>
        <div className="LinksOfInterest-container_card">
          <a
            href="https://www.aa2000.com.ar/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">AA2000</p>
            <p className="LinksOfInterest-container_card--links__txt">Argentina Airports 2000</p>
          </a>
          <a
            href="https://www.tca.aero/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">TCA</p>
            <p className="LinksOfInterest-container_card--links__txt">Argentine Cargo Terminal (fiscal warehouse)</p>
          </a>
          <a
            href="https://jurca.org.ar/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">JURCA</p>
            <p className="LinksOfInterest-container_card--links__txt">Board of Representatives of Airlines Companies</p>
          </a>
          <a
            href="https://www.argentina.gob.ar/anac"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">ANAC</p>
            <p className="LinksOfInterest-container_card--links__txt">Argentine National Civil Aviation Administration</p>
          </a>
          <a
            href="https://www.argentina.gob.ar/orsna"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">ORSNA</p>
            <p className="LinksOfInterest-container_card--links__txt">Regulatory Body of the National Airport System</p>
          </a>
          <a
            href="https://aaaci.org.ar/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">AAACI</p>
            <p className="LinksOfInterest-container_card--links__txt">Argentine Association of International Cargo Agents</p>
          </a>
          <a
            href="https://www.argentina.gob.ar/senasa"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">SENASA</p>
            <p className="LinksOfInterest-container_card--links__txt">National Service for Health and Agri-Food Quality</p>
          </a>
          <a
            href="https://www.afip.gob.ar/landing/default.asp"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">AFIP</p>
            <p className="LinksOfInterest-container_card--links__txt">Federal Public Revenue Administration</p>
          </a>
          <a
            href="https://www.iata.org/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">IATA</p>
            <p className="LinksOfInterest-container_card--links__txt">International Airlines Transport Association</p>
          </a>
          <a
            href="https://www.argentina.gob.ar/transporte/intercargo"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">INTERCARGO</p>
            <p className="LinksOfInterest-container_card--links__txt">(Ramp Service)</p>
          </a>
          <a
            href="https://www.bna.com.ar/Personas"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">BNA</p>
            <p className="LinksOfInterest-container_card--links__txt">(National Bank)</p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default LinksOfInterest;
