import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Home from "../components/Home/Home";
import Institutional from "../components/Institutional/Institutional";
import Representations from "../components/Representations/Representations";
import Services from "../components/Services/Services";
import Forms from "../components/Forms/Forms";
import LinksOfInterest from "../components/LinksOfInterest/LinksOfInterest";
import Contact from "../components/Contact/Contact";
import "./Pages.scss";

const Es = () => {
  return (
    <section className="container">
      <ul id="cards">
        <li id="card2" className="card">
          <div className="card-body">
            <NavBar />
          </div>
        </li>
          <li id="card3" className="card">
            <div className="card-body">
              <Home />
            </div>
          </li>
          <li id="card4" className="card">
            <div className="card-body">
              <Institutional />
            </div>
          </li>
          <li id="card5" className="card">
            <div className="card-body">
              <Representations />
            </div>
          </li>
          <li id="card6" className="card">
            <div className="card-body">
              <Services />
            </div>
          </li>
          <li id="card7" className="card">
            <div className="card-body">
              <Forms />
            </div>
          </li>
          <li id="card8" className="card">
            <div className="card-body">
              <LinksOfInterest />
            </div>
          </li>
          <li id="card9" className="card">
            <div className="card-body">
              <Contact />
            </div>
          </li>
      </ul>
    </section>
  );
};

export default Es;
