import React from "react";
import "./Forms.scss";

const FormsEn = () => {
  const SecurityDeclaration30días = () => {
    const url = process.env.PUBLIC_URL + "Forms/SecurityDeclaration30días.docx";

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "SecurityDeclaration30días.docx";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  };

  const SecurityDeclaration180días = () => {
    const url =
      process.env.PUBLIC_URL + "Forms/SecurityDeclaration180días.docx";

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "SecurityDeclaration180días.docx";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  };

  const SecurityDeclarationTSA = () => {
    const url = process.env.PUBLIC_URL + "Forms/SecurityDeclarationTSA.docx";

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "SecurityDeclarationTSA.docx";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  };

  return (
    <section id="Forms" className="Forms">
      <div className="Forms-container">
        <div className="Forms-container_title">Forms</div>
        <div className="Forms-container_subTitle">
          Download the forms in .docx format
        </div>
        <div className="Forms-container_buttons">
          <div className="Forms-container_buttons--button">
            <div
              className="Forms-container_buttons--button__txt"
              onClick={SecurityDeclaration30días}
            >
              - Security Declaration 30 Days
            </div>
          </div>
          <div className="Forms-container_buttons--button">
            <div
              className="Forms-container_buttons--button__txt"
              onClick={SecurityDeclaration180días}
            >
              - Security Declaration 180 Days
            </div>
          </div>
          <div className="Forms-container_buttons--button">
            <div
              className="Forms-container_buttons--button__txt"
              onClick={SecurityDeclarationTSA}
            >
              - Security Declaration TSA
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormsEn;
