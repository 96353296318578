import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Es from "./pages/Es";
import En from "./pages/En";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Es />} />
          <Route path="/En" element={<En />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
