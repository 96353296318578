import React, { useState } from "react";
import "./Contact.scss";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: name,
      email: email,
      phone: phone,
      company: company,
      message: message,
    };

    const serverURL = "https://formsubmit.co/ajax/info@bluewings.com.ar";

    fetch(serverURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(templateParams),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Mensaje enviado correctamente:", data);
        alert("Mensaje enviado correctamente");

        setName("");
        setEmail("");
        setPhone("");
        setCompany("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);
        alert("Ocurrió un error al enviar el mensaje");
      });
  };

  return (
    <section id="Contact" className="Footer">
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <h2>Contacto</h2>
          <label htmlFor="name">Nombre:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Correo electrónico:</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Telefono:</label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="company">Empresa:</label>
          <input
            type="text"
            id="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Mensaje:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Enviar
        </button>
      </form>
      <div className="Footer-contact">
        <div className="Footer-contact_card">
          <p className="Footer-contact_card--txt">CASA CENTRAL</p>
          <p className="Footer-contact_card--txt">
            Carlos Pellegrini 1063 - 7mo. piso - Of. B ( C1009ABU ) C.A.B.A.
            Argentina
          </p>
          <iframe
            className="Footer-contact_card--map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.3284452195708!2d-58.38612061795257!3d-34.59585528733696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccab7bea6ee25%3A0x670de8df4c6ba5f3!2sCarlos%20Pellegrini%201063%2C%20C1009ABU%20CABA!5e0!3m2!1ses-419!2sar!4v1696597275839!5m2!1ses-419!2sar"
          ></iframe>
          <p className="Footer-contact_card--txt">
            Atención al Cliente: importacion@bluewings.com.ar
          </p>
        </div>
        <div className="Footer-contact_card">
          <p className="Footer-contact_card--txt">EZEIZA</p>
          <p className="Footer-contact_card--txt">
            Edificio Edo 1er piso. Terminal de Cargas Aeropuerto Ezeiza (Retiro
            de Documentación)
          </p>
          <iframe
            className="Footer-contact_card--map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3275.869104517101!2d-58.545458824442115!3d-34.809234868380756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcd06e1fafcc61%3A0x5ca7ad7b2b057695!2sTerminal%20de%20Cargas%20Argentina%20(acceso%20camiones)!5e0!3m2!1ses-419!2sar!4v1696597489444!5m2!1ses-419!2sar"
          ></iframe>
          <p className="Footer-contact_card--txt">115480 - 5162</p>
          <p className="Footer-contact_card--txt">115480-5190</p>
          <p className="Footer-contact_card--txt">
            Operaciones: ezebw@bluewings.com.ar
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
