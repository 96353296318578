import React, { useState } from "react";
import "./Services.scss";

const Services = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const cardData = [
    {
      title: "GSA",
      img: "./Services/ServicesGsa.jpg",
      additionalInfo: [
        "Representation.",
        "Marketing.",
        "Export and import market tracking.",
        "Monthly statistics and reports.",
        "Sales.",
        "Reservations.",
        "Claims.",
        "Customer service.",
        "Call Plan.",
        "Goals and Budgets.",
        "Event organization.",
        "Mailings.",
      ],
      additionalInfo1: [],
    },
    {
      title: "GHA",
      img: "./Services/ServicesGha.jpg",
      additionalInfo: [
        "On Load:",
        "Acceptance and verification of export guides.",
        "Checking of cargo weight and dimensions (reserved vs. actual).",
        "Preparation and presentation of the Customs and Warehouse manifest.",
        "Checking and acceptance of Dangerous Goods.",
        "Acceptance of Mail.",
        "Preparation of ULDs (Unit Load Devices).",
        "ULD movement report.",
      ],
      additionalInfo1: [
        "On Unload:",
        "Coordination of necessary elements on the ramp with Intercargo upon arrival.",
        "Supervision of unloading by Intercargo (Ramp Service).",
        "Escort of cargo to the bonded warehouse.",
        "Acting as ATA (Customs Broker) for the airline.",
        "Verification of the origin manifest against guides (packages – kilograms – content - Part Shipments – required temperature, etc.).",
        "Entry of manifests into the Customs Malvina computer system.",
        "ULD un-palletizing.",
        "Supervision of un-palletizing at TCA (Terminal Cargas Argentina - bonded warehouse).",
        "Verification of package condition and weight.",
        "Condition reports.",
        "Mail delivery.",
      ],
    },
    {
      title: "Charter Flights",
      img: "./Services/ServicesVC2.jpg",
      additionalInfo: ["Additional information about Charter Flights service."],
      additionalInfo1: [],
    },
  ];

  const openModal = (card) => {
    setSelectedCard(card);
  };

  const closeModal = () => {
    setSelectedCard(null);
  };

  return (
    <section id="Services" className="Services">
      <div className="Services-container">
        <div className="Services-container_title">Services</div>
        <div className="Services-container_cards">
          {cardData.map((card, index) => (
            <div className="Services-container_cards--card" key={index}>
              <p className="Services-container_cards--card__title">
                {card.title}
              </p>
              <img
                className="Services-container_cards--card__img"
                src={card.img}
                alt={card.title}
              />
              <div className="Services-container_cards--card__info">
                <div
                  className="Services-container_cards--card__info---button"
                  onClick={() => openModal(card)}
                >
                  + INFO
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedCard && (
        <div className="modal">
          <div className="modal-content">
            <h2>{selectedCard.title}</h2>
            <div className="modal-content_info">
              <div className="modal-content_info--1">
                {selectedCard.additionalInfo.map((info, index) => (
                  <p key={index}>{info}</p>
                ))}
              </div>
              <div className="modal-content_info--2">
                {selectedCard.additionalInfo1.map((info, index) => (
                  <p key={index}>{info}</p>
                ))}
              </div>
              <div className="modal-content_cont">
                <img
                  className="modal-content_cont--img"
                  src={selectedCard.img}
                ></img>
              </div>
            </div>
            <button className="close-button" onClick={closeModal}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Services;
