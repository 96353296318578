import React from "react";
import "./LinksOfInterest.scss";

const LinksOfInterest = () => {
  return (
    <section id="LinksOfInterest" className="LinksOfInterest">
      <div className="LinksOfInterest-container">
        <div className="LinksOfInterest-container_title">Links de interés</div>
        <div className="LinksOfInterest-container_card">
          <a
            href="https://www.aa2000.com.ar/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">AA2000</p>
            <p className="LinksOfInterest-container_card--links__txt"> Aeropuertos Argentina 2000</p>
          </a>
          <a
            href="https://www.tca.aero/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">TCA</p>
            <p className="LinksOfInterest-container_card--links__txt">Terminal Cargas Argentina ( bodega fiscal )</p>
            
          </a>
          <a
            href="https://jurca.org.ar/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">JURCA</p>
            <p className="LinksOfInterest-container_card--links__txt">Junta de Representantes de Compañías Aéreas</p>
          </a>
          <a
            href="https://www.argentina.gob.ar/anac"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">ANAC</p>
            <p className="LinksOfInterest-container_card--links__txt">Administración Nacional de Aviación Civil Argentina</p>
          </a>
          <a
            href="https://www.argentina.gob.ar/orsna"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">ORSNA</p>
            <p className="LinksOfInterest-container_card--links__txt">Organismo Regulador del Sistema Nacional de Aeropuertos</p>
          </a>
          <a
            href="https://aaaci.org.ar/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">AAACI</p>
            <p className="LinksOfInterest-container_card--links__txt">Asociación Argentina de Agentes de Cargas Internacional</p>
          </a>
          <a
            href="https://www.argentina.gob.ar/senasa"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">SENASA</p>
            <p className="LinksOfInterest-container_card--links__txt">Servicio Nacional de Sanidad y Calidad Agroalimentaria</p>
            
          </a>
          <a
            href="https://www.afip.gob.ar/landing/default.asp"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">AFIP</p>
            <p className="LinksOfInterest-container_card--links__txt">Administración Federal de Ingresos Públicos</p>
            
          </a>
          <a
            href="https://www.iata.org/"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">IATA</p>
            <p className="LinksOfInterest-container_card--links__txt">Internacional Airlines Transport Association</p>
            
          </a>
          <a
            href="https://www.argentina.gob.ar/transporte/intercargo"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">INTERCARGO</p>
            <p className="LinksOfInterest-container_card--links__txt">( Servicio de Rampa )</p>
            
          </a>
          <a
            href="https://www.bna.com.ar/Personas"
            target="_blank"
            className="LinksOfInterest-container_card--links"
          >
            <p className="LinksOfInterest-container_card--links__link">BNA</p>
            <p className="LinksOfInterest-container_card--links__txt">( Banco Nacion )</p>
            
          </a>
        </div>
      </div>
    </section>
  );
};

export default LinksOfInterest;
