import React from "react";
import "./Home.scss";

const Home = () => {
  return (
    <section id="Home" className="Home">
      <div className="Home-container">
        <h1 className="Home-container_title">
          <div className="Home-container_title--txt">GSSA</div>
          <div className="Home-container_title--subtxt">
            GENERAL SALES AND SERVICES AGENT
          </div>
        </h1>
      </div>
    </section>
  );
};

export default Home;
