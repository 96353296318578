import React, { useState } from "react";
import "./Services.scss";

const Services = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const cardData = [
    {
      title: "GSA",
      img: "./Services/ServicesGsa.jpg",
      additionalInfo: [
        "Representación.",
        "Marketing.",
        "Seguimiento del mercado de exportación e importación.",
        "Estadísticas e Informes mensuales.",
        "Ventas.",
        "Reservas.",
        "Reclamos.",
        "Atención al cliente.",
        "Call Plan.",
        "Metas y Presupuestos.",
        "Organización eventos.",
        "Mailings.",
      ],
      additionalInfo1: [],
    },
    {
      title: "GHA",
      img: "./Services/ServicesGha.jpg",
      additionalInfo: [
        "A la Carga:",
        "Aceptación y chequeo de guías de exportación",
        "Verificación del peso y dimensiones de las cargas ( reservado vs. actual )",
        "Confección y presentación manifiesto a la Aduana y Bodega",
        "Chequeo y aceptación de Mercancías Peligrosas",
        "Aceptación del Correo",
        "Preparación de los ULD´s",
        "Informe movimiento de ULD´s",
      ],
      additionalInfo1: [
        "A la Descarga:",
        "Coordinación de elementos necesarios en rampa con Intercargo para el arribo",
        "Supervización de la descarga por parte de Intercargo ( Servicio de Rampa )",
        "Acompañamiento carga a bodega fiscal",
        "Actuamos como ATA ( Agente de Transporte Aduanero ) para la línea aérea.",
        "Chequeo manifiesto de orígen contra guías ( bultos – kilos – contenido - Part Shipments – temperatura requerida, etc.).",
        "Ingreso de los manifiestos al Sistema informático Malvina de la aduana",
        "Despalletizado ULD´s .",
        "Supervización del despalletizado en TCA ( Terminal Cargas Argentina - bodega fiscal ) .",
        "Verificación estado bultos y peso.",
        "Actas de condición.",
        "Entrega del Correo",
      ],
    },
    {
      title: "Vuelos Chárter",
      img: "./Services/ServicesVC2.jpg",
      additionalInfo: [
        "Atención Vuelos Chárter.",
      ],
      additionalInfo1: [],
    },
  ];

  const openModal = (card) => {
    setSelectedCard(card);
  };

  const closeModal = () => {
    setSelectedCard(null);
  };

  return (
    <section id="Services" className="Services">
      <div className="Services-container">
        <div className="Services-container_title">Servicios</div>
        <div className="Services-container_cards">
          {cardData.map((card, index) => (
            <div className="Services-container_cards--card" key={index}>
              <p className="Services-container_cards--card__title">
                {card.title}
              </p>
              <img
                className="Services-container_cards--card__img"
                src={card.img}
                alt={card.title}
              />
              <div className="Services-container_cards--card__info">
                <div
                  className="Services-container_cards--card__info---button"
                  onClick={() => openModal(card)}
                >
                  + INFO
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedCard && (
        <div className="modal">
          <div className="modal-content">
            <h2>{selectedCard.title}</h2>
            <div className="modal-content_info">
              <div className="modal-content_info--1">
                {selectedCard.additionalInfo.map((info, index) => (
                  <p key={index}>{info}</p>
                ))}
              </div>
              <div className="modal-content_info--2">
                {selectedCard.additionalInfo1.map((info, index) => (
                  <p key={index}>{info}</p>
                ))}
              </div>
              <div className="modal-content_cont">
                <img
                  className="modal-content_cont--img"
                  src={selectedCard.img}
                ></img>
              </div>
            </div>
            <button className="close-button" onClick={closeModal}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Services;
