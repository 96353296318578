import React from "react";
import "./Institutional.scss";

const Institutional = () => {
  return (
    <section id="Institutional" className="Institutional">
      <div className="Institutional-container">
        <div className="Institutional-container_card1">
          <p className="Institutional-container_card1--txt">
            Nos avalan más de 20 años de experiencia como GSA en Argentina.
          </p>
          <div className="Institutional-container_card2">
            <div className="Institutional-container_card2--contentTxt">
              <p className="Institutional-container_card2--contentTxt__txt">
                Nuestra empresa fue fundada en junio de 2002 para asumir la
                representación de KLM Royal Dutch Airlines en el área de pasajes
                con una operación diaria vía SAO.
              </p>
              <p className="Institutional-container_card2--contentTxt__txt">
                Nuestra misión es ser un proveedor de servicios de tercerización
                / outsourcing de primer nivel para líneas aéreas.
              </p>
              <p className="Institutional-container_card2--contentTxt__txt">
                Nuestra visión es entregar a nuestros clientes Calidad y
                Confiabilidad, además de ofrecer una red de servicios integrados
                de Ventas, Marketing, Reservaciones y Handling para Cargas,
                asegurándole a la empresa representada el mantenimiento de su
                Identidad de Marca, trabajando como SOCIOS en el desarrollo del
                mercado local.
              </p>
              <p className="Institutional-container_card2--contentTxt__txt">
                Nuestro equipo de trabajo está compuesto por personal calificado
                y de larga trayectoria en esta industria.
              </p>
              <p className="Institutional-container_card2--contentTxt__txt">
                Nos especializamos en brindar servicios de Cargas, así como en
                el manejo integral de operación completa "On / Off Line"
              </p>
            </div>
            <div className="Institutional-container_card2--contentImg">
              <img
                className="Institutional-container_card2--contentImg__imgMobile"
                src="./Institutional/InstitutionalMobile.jpg"
              ></img>
              <img
                className="Institutional-container_card2--contentImg__imgDesktop"
                src="./Institutional/InstitutionalDesktop.jpg"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Institutional;
