import React from "react";
import NavBar from "../components/NavBar/NavBar-En";
import Home from "../components/Home/Home";
import InstitutionalEn from "../components/Institutional/Institutional-En";
import RepresentationsEn from "../components/Representations/Representations-En";
import ServicesEn from "../components/Services/Services-En";
import FormsEn from "../components/Forms/Forms-En";
import LinksOfInterestEn from "../components/LinksOfInterest/LinksOfInterest-En";
import ContactEn from "../components/Contact/Contact-En";

const En = () => {
  return (
    <section className="container">
      <ul id="cards">
        <li id="card2" className="card">
          <div className="card-body">
            <NavBar />
          </div>
        </li>
        <li id="card3" className="card">
          <div className="card-body">
            <Home />
          </div>
        </li>
        <li id="card4" className="card">
          <div className="card-body">
            <InstitutionalEn />
          </div>
        </li>
        <li id="card5" className="card">
          <div className="card-body">
            <RepresentationsEn />
          </div>
        </li>
        <li id="card6" className="card">
          <div className="card-body">
            <ServicesEn />
          </div>
        </li>
        <li id="card7" className="card">
          <div className="card-body">
            <FormsEn />
          </div>
        </li>
        <li id="card8" className="card">
          <div className="card-body">
            <LinksOfInterestEn />
          </div>
        </li>
        <li id="card9" className="card">
          <div className="card-body">
            <ContactEn />
          </div>
        </li>
      </ul>
    </section>
  );
};

export default En;
